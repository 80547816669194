import React, { useRef } from "react";
import Nav from "../../components/nav/Nav";
import "./Contact.css";
import Footer from "../../components/footer/Footer";
import { FaGithub } from "react-icons/fa";
import { MdLocationPin } from "react-icons/md";
import toast, { Toaster } from "react-hot-toast";
import { FaLinkedin } from "react-icons/fa";
function Contact() {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    toast.success("Message sent successfully !");
  };
  return (
    <div className="contact">
      <Nav />
      <div className="contact__contents">
        <div className="contact__contentsTop">
          <h1>Get In Touch</h1>
          <p>Need quotation? Feel free to message.</p>
        </div>
        <div className="contact__contentsBottom">
          <div className="contact__contentsBottomLeftOuter">
            <div className="contact__contentsBottomLeft">
              <h3>Contact Information</h3>
              <p>
                Fill up the form and the developer will get back to you soon.
              </p>
              <div className="contact__contentsBottomLeftContact">
                <div className="phone">
                  <FaLinkedin className="icon" />
                  <a href="https://www.linkedin.com/kirankhanalleo">
                    kirankhanalleo
                  </a>
                </div>
                <div className="email">
                  <FaGithub className="icon" />
                  <a href="https://www.github.com/kirankhanalleo">
                    kirankhanalleo
                  </a>
                </div>
                <div className="location">
                  <MdLocationPin className="icon" />
                  Kathmandu, Bagmati, Nepal
                </div>
              </div>
            </div>
          </div>
          <div className="contact__contentsBottomRight">
            <form ref={form} onSubmit={sendEmail}>
              {/* <label for="First Name">First Name</label> */}
              <input
                type="text"
                name="f_name"
                placeholder="First Name"
                required
              />
              {/* <label for="Last Name">Last Name</label> */}
              <input
                type="text"
                name="l_name"
                placeholder="Last Name"
                required
              />
              {/* <label for="Mail">Mail</label> */}
              <input type="email" name="email" placeholder="Mail" required />
              {/* <label for="Phone">Phone</label> */}
              <input type="phone" name="phone" placeholder="Phone" required />
              {/* <label for="Message">Message</label> */}
              <textarea
                cols="30"
                rows="4"
                name="message"
                placeholder="Message"
                required
              ></textarea>
              <button type="submit" className="submit__button">
                Send Message
              </button>
              <Toaster />
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
