import React, { useState, useEffect } from "react";
import "./Nav.css";
import { useNavigate } from "react-router-dom";

function Nav() {
  const navigate = useNavigate();
  const [show, handleShow] = useState(false);
  const transitionNavBar = () => {
    if (window.scrollY > 100) {
      handleShow(true);
    } else {
      handleShow(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", transitionNavBar);
    return () => window.removeEventListener("scroll", transitionNavBar);
  }, []);
  return (
    <div className={`nav ${show && "nav__hide"}`}>
      <div className="nav__components">
        <div className="nav__componentsLeft">
          <b onClick={() => navigate("/")}>kiran.me</b>
        </div>
        <div className="nav__componentsRight">
          <p onClick={() => navigate("/contact")}>Chat now</p>
        </div>
      </div>
    </div>
  );
}

export default Nav;
