import React from "react";
import "./About.css";
import downloadCv from "../../assets/downloadCv.png";
function About() {
  return (
    <div className="about">
      <div className="about__content">
        <h2>Hi, I am Kiran. Nice to meet you.</h2>
        <div className="about__contentIntro">
          <p>
            Experienced Full Stack Developer with 3+ years in React.js
            freelancing. Currently working with React and Java Spring Boot to
            build robust web applications. I am skilled in creating
            user-friendly interfaces and integrating APIs. I recognize myself as
            a ‘forever student’ anxious to build myself as web developer.
          </p>
        </div>
        <div className="buymeContent">
          <a href="https://www.buymeacoffee.com/kirankhanalleo">
            <img
              className="logo__buymeacoffee"
              src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
              alt="Buy Me A Coffee"
            />
          </a>
          <a href="/assets/Kiran_Khanal_CV_2024.pdf" download>
            <img
              className="logo__buymeamomo"
              src={downloadCv}
              alt="download Cv"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default About;
