import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Contact from "./screens/contact/Contact";
import HomeScreen from "./screens/Home/HomeScreen";

function App() {
  return (
    <div className="app">
      <Routes>
        <Route exact path="/" element={<HomeScreen />} />
        <Route exact path="/contact" element={<Contact />} />
      </Routes>
    </div>
  );
}

export default App;
