import React from "react";
import Banner from "../../components/banner/Banner";
import "./HomeScreen.css";
import Footer from "../../components/footer/Footer";
import About from "../../components/about/About";
import Skills from "../../components/skills/Skills";
function HomeScreen() {
  return (
    <div className="homeScreen">
      <Banner />
      <About />
      <Skills />
      <Footer />
    </div>
  );
}

export default HomeScreen;
