import React from "react";
import "./Skills.css";
import { FaJava, FaReact } from "react-icons/fa";
import { MdOutlineSchool } from "react-icons/md";
function Skills() {
  return (
    <div className="skills">
      <div className="skills__content">
        <div className="skills__contentInner">
          <div className="skills__contentInner__div1">
            <FaJava className="innerdiv__logo" />
            <p className="innerdiv__title">Java Developer</p>
            <p className="innerdiv__para">
              I relish using Java programming language to create APIs and solve
              real-world problems. 
            </p>
            <p className="innerdiv__paraLang">Areas I expertise:</p>
            <p className="innerdiv__paralangLang">
              Core Java, Spring Boot Framework
            </p>
            <p className="innerdiv__tools innerdiv__paraLang">Dev Tools:</p>
            <p className="innerdiv__devenv">Intellij Ultimate</p>
            <p className="innerdiv__devenv">Maven</p>
            <p className="innerdiv__devenv">Git</p>
            <p className="innerdiv__devenv">OpenAPI</p>
            <p className="innerdiv__devenv">Azure</p>
            <p className="innerdiv__devenv">Workbench</p>
          </div>
          <div className="skills__contentInner__div2">
            <FaReact className="innerdiv__logo" />
            <p className="innerdiv__title">React.js Developer</p>
            <p className="innerdiv__para">
              I prefer building things from scratch and displaying my ideas in
              the browser.
            </p>
            <p className="innerdiv__paraLang">Areas I expertise:</p>
            <p className="innerdiv__paralangLang">
              Typescript, Scss, API Integration
            </p>
            <p className="innerdiv__tools innerdiv__paraLang">Dev Tools:</p>
            <p className="innerdiv__devenv">VS Code</p>
            <p className="innerdiv__devenv">npm</p>
            <p className="innerdiv__devenv">Redux</p>
            <p className="innerdiv__devenv">Axios</p>
            <p className="innerdiv__devenv">Tailwind</p>
            <p className="innerdiv__devenv">Material UI</p>
          </div>
          <div className="skills__contentInner__div3">
            <MdOutlineSchool className="innerdiv__logo" />
            <p className="innerdiv__title">Learner</p>
            <p className="innerdiv__para">
              I am a lifelong learner. Self-development and knowledge are the
              most valuable investments.
            </p>
            <p className="innerdiv__paraLang">My Learning Platforms:</p>
            <p className="innerdiv__paralangLang">
              Skillshare, Coursera, Youtube
            </p>
            <p className="innerdiv__tools innerdiv__paraLang">Learner Stats:</p>
            <p className="innerdiv__devenv">Bootcamps</p>
            <p className="innerdiv__devenv">CheatSheets</p>
            <p className="innerdiv__devenv">Challenges</p>
            <p className="innerdiv__devenv">Experience</p>
            <p className="innerdiv__devenv">Code Review</p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Skills;
